<!-- Ecommerce Sidebar Starts -->
<div class="row mt-1 d-none d-lg-block">
  <div class="col-sm-12">
    <h6 class="filter-heading">Filters</h6>
  </div>
</div>
<div class="card">
  <div class="card-body">
    <!-- Price Filter starts -->
    <div class="multi-range-price">
      <h6 class="filter-title mt-0">Verification</h6>
      <ul class="list-unstyled price-range" id="price-range">
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="priceAll" name="price-range" class="custom-control-input"value="1" checked (change)="filterOffersByRange($event)"/>
            <label class="custom-control-label" for="priceAll">Partner Verified</label>
          </div>
        </li>
        <li>
          <div class="custom-control custom-radio">
            <input type="radio" id="priceRange1" name="price-range" class="custom-control-input"value="2"  (change)="filterOffersByRange($event)" />
            <label class="custom-control-label" for="priceRange1">Not Verified</label>
          </div>
        </li>

      </ul>
    </div>
    <!-- Price Filter ends -->

    <!-- Brands starts -->
    <div class="brands">
      <h6 class="filter-title">Offer Tags</h6>
      <ul class="list-unstyled brand-list">
        <li  *ngFor="let tag of tags">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="{{tag.id}}" value="{{tag.tag}}" (change)="tagValue($event)"/>
            <label class="custom-control-label" for="{{tag.id}}">{{tag.tag}}</label>
          </div>

        </li>
      </ul>
    </div>
    <!-- Brand ends -->

    <!-- Rating starts -->
    <!-- <div id="ratings">
      <h6 class="filter-title">Ratings</h6>
      <div class="ratings-list">
        <a href="javascript:void(0)">
          <span class="rating rating-sm">
            <ngb-rating [rate]="4" [readonly]="true" [max]="5" class="outline-none">
              <ng-template let-fill="fill" let-index="index"
                ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
              ></ng-template>
            </ngb-rating>
          </span>
          & up
        </a>
        <div class="stars-received">160</div>
      </div>
      <div class="ratings-list">
        <a href="javascript:void(0)">
          <span class="rating rating-sm">
            <ngb-rating [rate]="3" [readonly]="true" [max]="5" class="outline-none">
              <ng-template let-fill="fill" let-index="index"
                ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
              ></ng-template>
            </ngb-rating>
          </span>
          & up
        </a>
        <div class="stars-received">176</div>
      </div>
      <div class="ratings-list">
        <a href="javascript:void(0)">
          <span class="rating rating-sm">
            <ngb-rating [rate]="2" [readonly]="true" [max]="5" class="outline-none">
              <ng-template let-fill="fill" let-index="index"
                ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
              ></ng-template>
            </ngb-rating>
          </span>
          & up
        </a>
        <div class="stars-received">291</div>
      </div>
      <div class="ratings-list">
        <a href="javascript:void(0)">
          <span class="rating rating-sm">
            <ngb-rating [rate]="1" [readonly]="true" [max]="5" class="outline-none">
              <ng-template let-fill="fill" let-index="index"
                ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
              ></ng-template>
            </ngb-rating>
          </span>
          & up
        </a>
        <div class="stars-received">190</div>
      </div>
    </div> -->
    <!-- Rating ends -->

    <!-- Clear Filters Starts -->
    <div id="clear-filters">
      <button type="button" class="btn btn-block btn-primary" rippleEffect>Clear All Filters</button>
    </div>
    <!-- Clear Filters Ends -->
  </div>
</div>
<!-- Ecommerce Sidebar Ends -->
