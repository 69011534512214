<div class="content-wrapper container-xxl p-0">
  <div class="content-body">

    <section id="faq-search-filter">
      <div class="card faq-search" style="background-image: url('assets/images/banner/banner.png')">
        <div class="m-2">
          <div class="col-12 text-center">
            <h2>Buy, Sell, Invest & Trade Bitcoin</h2>
            <p class="mb-3 text-center">
              Buy and sell Bitcoin using over 300 different payment methods. Start accepting payments and sending money
              worldwide with millions secured by escrow
            </p>
          </div>
          <div class="row ">
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">Bank</h2>
                    <p class="card-text">Transfers</p>
                  </div>
                  <div class="avatar bg-light-primary p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="credit-card" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">Online</h2>
                    <p class="card-text">Wallets</p>
                  </div>
                  <div class="avatar bg-light-success p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="briefcase" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">Digital</h2>
                    <p class="card-text">Currencies</p>
                  </div>
                  <div class="avatar bg-light-danger p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="codesandbox" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6 col-12">
              <div class="card">
                <div class="card-header">
                  <div>
                    <h2 class="font-weight-bolder mb-0">Cash</h2>
                    <p class="card-text">Transfers</p>
                  </div>
                  <div class="avatar bg-light-warning p-50 m-0">
                    <div class="avatar-content">
                      <i data-feather="dollar-sign" class="font-medium-5"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>




    <!-- Knowledge base -->
    <section id="knowledge-base-content">
      <div class="row kb-search-content-info match-height">
        <div
          *ngFor="let knowledgeBaseRef of data2"
          class="col-md-4 col-sm-6 col-12 kb-search-content"
        >
          <div class="card">
            <a>
              <img src="{{ knowledgeBaseRef.img }}" class="card-img-top" alt="knowledge-base-image"/>
              <div class="card-body text-center">
                <h4>{{ knowledgeBaseRef.title }}</h4>
                <p class="text-body mt-1 mb-0">{{ knowledgeBaseRef.desc }}</p>
              </div>
            </a>
          </div>
        </div>


      </div>
    </section>
    <!-- Knowledge base ends -->


    <!-- contact us -->
    <section class="faq-contact">
      <div class="row mt-5 pt-75">
        <div class="col-12 text-center">
          <h2>You still have a question?</h2>
          <p class="mb-3">
            Join the community today, become a vendor and trade with millions around the world. Offer multiple payment
            methods that buyers like, and we will help you with all the resources and guidance needed to succeed.
          </p>
        </div>
        <div class="col-sm-6">
          <div class="card text-center faq-contact-card shadow-none py-1">
            <div class="card-body">
              <a href="{{coreConfig.app.appTelegramSupport}}" target="_blank">
                <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                  <i data-feather="message-circle" class="font-medium-3"></i>
                </div>
              </a>
              <h4>{{coreConfig.app.appName}} International</h4>
              <span class="text-body">Ask our community on telegram</span>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="card text-center faq-contact-card shadow-none py-1">
            <div class="card-body">
              <div class="avatar avatar-tag bg-light-primary mb-2 mx-auto">
                <i data-feather="mail" class="font-medium-3"></i>
              </div>
              <h4>{{coreConfig.app.appSupportEmail}}</h4>
              <span class="text-body">Best way to get answer faster!</span>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--/ contact us -->

    <!-- pricing free trial -->
    <div class="pricing-free-trial">
      <div class="row">
        <div class="col-12 col-lg-10 col-lg-offset-3 mx-auto">
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3">
              <h3 class="text-primary">Still not convinced? join for free!</h3>
              <h5>Sign up today to receive your free digital wallet. You can immediately start buying or selling
                Bitcoin, hassle-free</h5>
              <a href="/pages/register" class="btn btn-primary mt-2 mt-lg-3" rippleEffect>Okay, get started</a>
            </div>

            <!-- image -->
            <img
              src="assets/images/illustration/pricing-Illustration.svg"
              class="pricing-trial-img img-fluid d-none d-lg-block"
              alt="svg img"
            />
          </div>
        </div>
      </div>
    </div>
    <!--/ pricing free trial -->


  </div>

</div>
