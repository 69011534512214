
<section class="multiple-select">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6 col-12">
                            <label>Pay Via</label>

                            <div class="form-group">
                                <!-- Basic Multi select -->
                                <ng-select
                                    [items]="methods"
                                    bindLabel="method"
                                    bindValue="method"
                                    [(ngModel)]="filters.method"
                                    [loading]="selectBasicLoading"
                                >
                                </ng-select>
                                <!--/ Basic Multi select -->
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <label>I want to buy</label>

                            <div class="form-group">
                                <!-- Select Multi with group -->
                                <input
                                    type="text"
                                    id="last-name-column"
                                    class="form-control"
                                    placeholder="Amount"
                                    [(ngModel)]="amount"
                                />

                                <!--/ Select Multi with group -->
                            </div>
                        </div>
                        <div class="col-sm-6 col-12">
                            <label>Preffered currency</label>

                            <div class="form-group">
                                <!-- Basic Multi select -->
                                <ng-select
                                    [items]="currencies"
                                    bindLabel="currency"
                                    bindValue="currency"
                                    [(ngModel)]="filters.currency"
                                    [loading]="selectBasicLoading"
                                >
                                </ng-select>
                                <!--/ Basic Multi select -->
                            </div>
                        </div>

                        <div class="col-12">
                            <button
                                type="button"
                                class="btn btn-primary btn-block"

                                rippleEffect
                            >
                                <span [data-feather]="'search'" [class]="'mr-25'"></span
                                >FIND OFFERS
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
