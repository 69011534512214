<!-- Bookmarked Icons -->
<ul class="nav navbar-nav bookmark-icons">

</ul>
<!-- Bookmarked Icons -->

<ul class="nav navbar-nav">
  <li class="nav-item d-none d-md-block">
    <!-- Bookmark Icon -->
    <a class="btn btn-outline-primary mr-1"   [routerLink]="'/offers/bitcoin/buy'">Buy Bitcoin</a>
    <a class="btn btn-outline-primary"  [routerLink]="'/offers/bitcoin/sell'">Sell Bitcoin</a>

    <!--/ Bookmark Icon -->

  </li>

  <li class="nav-item d-none d-lg-block">
    <a class="btn btn-outline"  [routerLink]="'/offers/bitcoin/create'">Create Offer</a>
    <a class="btn btn-outline"  [routerLink]="'/wallet/main'">My wallet</a>
  </li>

</ul>
