<!-- Footer -->


<div class='row d-flex justify-content-between d-sm-block d-md-none' *ngIf='showActions'>
  <div class='col-6 '>
    <button type='button' class='btn btn-primary btn-block' rippleEffect (click)="toggleSidebar('chat-sidebar')">
      <span [data-feather]="'check'" [class]="'mr-25'"></span>Actions
    </button>

  </div>
  <div class='col-6'>
    <button type='button' class='btn btn-outline-primary btn-block' rippleEffect
            (click)="toggleSidebar('chat-sidebar')">
      <span [data-feather]="'message-square'" [class]="'mr-25'"></span>Chat
    </button>
  </div>
</div>
<p class='clearfix mb-0' *ngIf='!showActions'>
  <span class='float-md-left d-block d-md-inline-block mt-25'>
    COPYRIGHT &copy; {{ year }}
    <span class='d-none d-sm-inline-block'>. All rights Reserved</span>
  </span>
</p>

<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf='coreConfig.layout.scrollTop'></app-scroll-top>


