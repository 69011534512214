<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
                <span class="brand-logo">
                    <img src="/assets/images/logo/logo.svg" alt="brand-logo" width="20" height="20"/>
                </span>
        <h2 class="brand-text mb-0">{{coreConfig.app.appName}}</h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
      <!--/ BUY FOR SMALL -->
      <li class="d-md-none"><a class="nav-link" [href]="'offers/bitcoin/buy'"><i class="ficon"
                                                                                         data-feather="chevron-down"></i
      ><span class="badge badge-pill badge-primary badge-up bg-darken-2">buy</span></a></li>
      <!--/ BUY FOR SMALL -->

      <!--/ SELL FOR SMALL -->
      <li class="d-md-none"><a class="nav-link" [href]="'offers/bitcoin/sell'"><i class="ficon"
                                                                                          data-feather="chevron-up"></i
      ><span class="badge badge-pill badge-success badge-up bg-darken-2">sell</span></a></li>
      <!--/ SELL FOR SMALL -->

    </ul>
    <!--/ Menu Toggler -->

    <!-- Buy -->
    <app-navbar-bookmark></app-navbar-bookmark>
    <!--/ Buy -->
    <!-- Sll -->
    <app-navbar-sell></app-navbar-sell>
    <!--/ Sell -->
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
                <span
                  [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'"
                  class="ficon font-medium-5 feather"
                ></span>
      </a>
    </li>
    <!--/ Toggle skin -->


    <ng-template #elseBlock>
      <li class="nav-item d-block">
        <a class="btn btn-outline-primary" [routerLink]="'pages/login'" rippleEffect>Login</a>
      </li>
      <li><a type="button" class="btn btn-icon btn-primary ml-1"  [routerLink]="'pages/register'" rippleEffect>
        <span [data-feather]="'user-plus'"></span></a
      ></li>
    </ng-template>
    <!-- Search -->
    <app-navbar-search *ngIf="currentUser!.email; else elseBlock"></app-navbar-search>
    <!--/ Search -->

    <!-- Cart -->
    <!-- <app-navbar-cart></app-navbar-cart> -->
    <!--/ Cart -->

    <!-- Notification -->
    <app-navbar-notification *ngIf="currentUser!.email"></app-navbar-notification>
    <!--/ Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user" *ngIf="currentUser!.email">
      <a
        class="nav-link dropdown-toggle dropdown-user-link "
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <ng-container>
          <div class="user-nav d-sm-flex d-none">
            <span class="user-status">{{currentUser!.email}}</span>
            <span class="user-name font-weight-bolder">Level</span>

          </div>
          <span class="avatar"
          ><img
            class="round "
            src="assets/images/avatars/avatar.webp"
            alt="avatar"
            height="40"
            width="40"/><span class="avatar-status-online"></span
          ></span>
        </ng-container>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarUserDropdown"
        class="dropdown-menu dropdown-menu-right"
      >
        <a ngbDropdownItem [routerLink]="'/users/'+currentUser.username"
        ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        ><a ngbDropdownItem [routerLink]="['pages/account-settings']"
      ><span [data-feather]="'settings'" [class]="'mr-50'"></span> Settings</a
      >
        <a ngbDropdownItem href="https://t.me/+uBy5ijI8YaU2YmZk" target="_blank"
        ><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Our Community</a
        >

        <a ngbDropdownItem (click)="copy_link()" target="_blank"
        ><span [data-feather]="'user-plus'" [class]="'mr-50'"></span> Affiliate Link</a
        >
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem href="mailto:{{coreConfig.app.appSupportEmail}}?subject=Support%20Inquiry&body=I%20would%20like%20to%20ask%20about..."
        ><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> Contact Us</a
        ><a ngbDropdownItem (click)="logout()"
      ><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a
      >
      </div>
    </li>


    <!--/ User Dropdown -->
  </ul>
</div>
