<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- app e-commerce details start -->
    <section class="app-ecommerce-details">
      <div class="card">
        <!-- Product Details starts -->
        <div class="card-body">
          <div class="row my-2">
            <div class="col-12">
              <h4 class="text-capitalize" *ngIf="offer.type =='buy'">
                Sell Bitcoin with {{ offer.method }}({{
                offer.currency
                }}) — {{ offer.label }}
              </h4>
              <h4 class="text-capitalize" *ngIf="offer.type =='sell'">
                Buy Bitcoin with {{ offer.method }}({{
                offer.currency
                }}) — {{ offer.label }}
              </h4>
              <span class="card-text item-company"
              >By
                                <a
                                  href="javascript:void(0)"
                                  class="company-name"
                                  [routerLink]="'/users/' + offer.offer_username"
                                >{{ offer.offer_username }}</a
                                ></span
              >

              <div style="margin-top: 10px" class="detail-title">
                <div class="badge badge-pill badge-light-secondary" *ngFor="let tag of tags">
                  {{tag}}
                </div>

              </div>

              <!--INPUT FORM -->
              <div class="row">
                <div class="col-12">
                  <div class="card">
                    <div class="card-body">
                      <!--WARNING ERRORS -->
                      <ngb-alert [type]="'danger'" [dismissible]="false">
                        <div class="alert-body" *ngIf="err">
                          {{ err }}
                        </div>
                      </ngb-alert>
                      <ngb-alert [type]="'warning'" [dismissible]="false">
                        <div class="alert-body" *ngIf="offer.offer_username == 'NastinesCodsi423'">
                          This offer is Corporate Verified and approved by our Sales team. It comes with 24-7 live Support and money back assurance
                        </div>
                      </ngb-alert>
                      <!--WARNING ERRORS -->
                      <form
                        class="auth-register-form mt-2"
                        [formGroup]="form"
                      >
                        <div class="row">
                          <!--FIAT INPUT -->
                          <div class="col-sm-6 col-12">
                            <label>I want to <span *ngIf="offer.type=='buy'">Sell</span> <span
                              *ngIf="offer.type=='sell'">Buy</span></label>

                            <div class="form-group">
                              <!-- Select Multi with group -->
                              <input
                                type="number"
                                id="buy-amount"
                                class="form-control"
                                placeholder="Amount"
                                (keypress)="onChangeEvent($event)"
                                [class.error]="
                                                                    submitted && f.amount.errors
                                                                "
                                formControlName="amount"
                              />
                              <span
                                *ngIf="submitted && f.amount.errors"
                                class="invalid-form"
                              >
                                                                <small
                                                                  class="form-text text-danger"
                                                                  *ngIf="f.amount.errors.required"
                                                                >This field is required!</small
                                                                >
                                                            </span>

                              <!--/ Select Multi with group -->
                            </div>
                          </div>
                          <!--FIAT INPUT -->
                          <!--BTC INPUT -->
                          <div class="col-sm-6 col-12">
                            <label>And receive</label>

                            <div class="form-group">
                              <!-- Select Multi with group -->
                              <input
                                type="text"
                                id="receive-amount"
                                class="form-control"
                                placeholder="Amount"
                                [class.error]="submitted" formControlName="amounttoreceive"
                              />

                              <!--/ Select Multi with group -->
                            </div>
                          </div>
                          <!--BTC INPUT -->
                          <div class="col-12">
                            <!--LOGGED OUT -->
                            <div *ngIf="logged == false">
                              <a type="button" class="btn btn-primary btn-block"
                                 href="/pages/login?offer={{productIdFromRoute}}">
                              <span [data-feather]="'user'"
                                    [class]="'mr-25 text-capitalize'"></span>LOGIN FIRST</a>
                              <br>
                              <ngb-alert [type]="'primary'" [dismissible]="false">
                                <div class="alert-body">
                                  We'll bring you right here after you login in to your account
                                </div>
                              </ngb-alert>
                            </div>
                            <!--LOGGED OUT -->
                            <!--LOGGED IN -->
                            <div *ngIf="logged == true">
                              <a type="button" class="btn btn-primary btn-block" (click)="openTrade(form)" rippleEffect>

                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                OPEN TRADE

                              </a>
                              <br>
                              <ngb-alert [type]="'primary'" [dismissible]="false">
                                <div class="alert-body">
                                  This will secure Bitcoins in Escrow and start a live trade chat
                                  with {{offer.offer_username}}

                                </div>
                              </ngb-alert>
                            </div>
                            <!--LOGGED IN -->


                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!--INPUT FORM -->


              <!--OFFER RATES AND INSTRUCTION -->
              <p class="card-text" *ngIf="offer.margin != null">
                Rate:
                <span class="text-success">
  {{ offer.margin === 0 ? '' : getMargin(offer.margin) + '%' }}  {{ offer.margin == 0 ? 'at current' : (offer.margin > 0 ? 'above' : 'below') }}
                  market price

  </span>
              </p>
              <p class="card-text">
                <span *ngIf="offer.type=='buy'">Sell</span> <span *ngIf="offer.type=='sell'">Buy</span> limits:
                <span class="text-success"
                >{{ offer.minimum | number: '1.2-2' }}-{{
                  offer.maximum | number: '1.2-2'
                  }}
                  {{ offer.currency }}</span
                >
              </p>
              <p class="card-text">
                Escrow Fee: <span class="text-success">0%</span>
              </p>
              <p class="card-text">
                Trade time limit: <span class="text-success">30 minutes</span>
              </p>
              <!--OFFER TERMS -->
              <hr/>
              <!--TRADER INFO -->
              <div class="product-color-options">
                <h6>Offer Terms and Conditions </h6>

              </div>
              <pre class="card-text" style="white-space: pre-wrap; max-width: 100%; font-size: 14px;background-color: white !important;">{{ offer.terms }}</pre>

              <!--OFFER RATES AND INSTRUCTION -->
              <hr/>
              <!--TRADER INFO -->
              <div class="product-color-options">
                <h6>About this {{offer.type | titlecase}}er
                </h6>

                <ul class="product-features list-unstyled">
                  <li>
                    <i
                      class="font-medium-3 fa"
                      [ngClass]="'fa-clock-o text-primary'"
                    ></i>
                    <span>Last Seen {{ offer.user_data?.last_seen}}</span>
                  <li>
                    <i
                      class="font-medium-3 fa"
                      [ngClass]="{
                          'fa-check text-success': offer.user_data?.id_verified == true,
                          'fa-times  text-danger': offer.user_data?.id_verified != true
                        }"
                    ></i>
                    <span>ID Verified</span>
                  </li>
                  <li>
                    <i
                      class="font-medium-3 fa"
                      [ngClass]="{
                          'fa-check text-success': offer.user_data?.phone_verified == true,
                          'fa-times  text-danger': offer.user_data?.phone_verified != true
                        }"
                    ></i>
                    <span>Phone Verified</span>
                  </li>
                  <li>
                    <i
                      class="font-medium-3 fa"
                      [ngClass]="{
                          'fa-check text-success': offer.user_data?.email_verified == true,
                          'fa-times  text-danger': offer.user_data?.email_verified != true
                        }"
                    ></i>
                    <span>Email Verified</span>
                  </li>
                  <li>
                    <i
                      class="font-medium-3 fa"
                      [ngClass]="{
                          'fa-check text-success': offer.user_data?.address_verified == true,
                          'fa-times  text-danger': offer.user_data?.address_verified != true
                        }"
                    ></i>
                    <span>Address Verified</span>
                  </li>
                </ul>
              </div>

              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <h4 class="item-price mr-1">{{ offer.user_data?.positive_feedback}}</h4>
                <div class="rating rating-sm pl-1 border-left mb-1">
                  <span [data-feather]="'thumbs-up'" [class]="'mr-25  text-success'"></span>
                </div>
              </div>
              <hr/>
              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <h4 class="item-price mr-1">{{ offer.user_data?.negative_feedback}}</h4>
                <div class="rating rating-sm pl-1 border-left mb-1">
                  <span [data-feather]="'thumbs-down'" [class]="'mr-25  text-danger'"></span>
                </div>
              </div>
              <!--TRADER INFO -->
              <!--WISHLIST -->
              <div class="d-flex flex-column flex-sm-row pt-1">

                <div class="btn-group dropdown-icon-wrapper btn-share" ngbDropdown>
                  <a ngbDropdownToggle
                     class="btn btn-outline-secondary btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                     rippleEffect
                  >
                    <i
                      class="fa mr-50"
                      [ngClass]="'fa-ban text-danger'"
                    ></i>
                    <span>Report</span>
                  </a>

                  <div class="dropdown-menu-right" ngbDropdownMenu>
                    <a (click)="report(offer_id,'SCAM_OFFER')" ngbDropdownItem href="javascript:void(0)">
                      <i class="fa mr-50 " [ngClass]="'fa-exclamation-triangle text-danger'"
                      ></i>
                      This offer is a scam
                    </a>
                    <a (click)="report(offer_id,'ILLOGICAL_RATE')" ngbDropdownItem href="javascript:void(0)">
                      <i class="fa mr-50" [ngClass]="'fa-exclamation-triangle text-danger'"
                      ></i>
                      This rate is illogical
                    </a>

                  </div>
                </div>

                <button
                  (click)="share(offer_id)"

                  type="button"
                  class="btn btn-icon hide-arrow btn-outline-secondary"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  rippleEffect
                >
                  <i data-feather="share-2"></i>
                </button>


              </div>
              <!--WISHLIST -->
            </div>
          </div>
        </div>
        <!-- Product Details ends -->

        <!-- CHAT FEATURES -->
        <div class="item-features">
          <div class="row text-center">
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="award"></i>
                <h4 class="mt-2 mb-1">Live Chat</h4>
                <p class="card-text">
                  Open the trade to chat with the seller. Be sure to keep trades
                  within the website. Don't give out your contact and talk to sellers
                  on social media outside the website, we'll be unable to
                  help you if something goes wrong.
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="clock"></i>
                <h4 class="mt-2 mb-1">30 Minute limit</h4>
                <p class="card-text">
                  When you open this secure trade, you have 30 minutes to send the
                  money to the seller and click 'I have paid' to activate your
                  buyer protection. If you don't click paid within the time limit
                  the trade will expire and BTC will be removed from escrow
                </p>
              </div>
            </div>
            <div class="col-12 col-md-4 mb-4 mb-md-0">
              <div class="w-75 mx-auto">
                <i data-feather="shield"></i>
                <h4 class="mt-2 mb-1">Secured with Escrow</h4>
                <p class="card-text">
                  With Escrow you can buy and sell crypto safely without risk. We
                  secure BTC on a trust account and enable you to send money to
                  the seller so that if you have any problem you can open a
                  dispute and we'll award the crypto to the right party,
                  Moderators are available for this trade. If you have any problem
                  open a dispute and we'll join in to help you.
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Item features ends -->
      </div>
    </section>
    <!-- app e-commerce details end -->
  </div>
</div>
