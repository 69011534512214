<li class="nav-item nav-search">
  <!-- Search icon -->
  <a class="nav-link nav-link-search" (click)="toggleSearch()"
    ><span [data-feather]="'search'" [class]="'ficon'"></span
  ></a>

  <!-- Search -->
  <div class="search-input" [class.open]="openSearchRef === true">
    <!-- Search Input -->
    <div class="search-input-icon"><span [data-feather]="'search'"></span></div>
    <input
      id="search_input"
      class="form-control input"
      type="text"
      placeholder="Search Active Users..."
      tabindex="-1"
      data-search="search"
      (keyup)="searchUpdate($event)"
      [(ngModel)]="searchText"
      #openSearch
    />
    <button class="btn search-input-close p-0" (click)="toggleSearch()">
      <span [data-feather]="'x'"></span>


    </button>

    <!-- Search List -->
    <ul class="search-list search-list-main" [perfectScrollbar] [class.show]="searchText !== ''" #pageList>
      <!-- Members -->
      <li class="d-flex align-items-center">
        <a href="javascript:void(0)"><h6 class="section-label mt-75 mb-0">Users</h6></a>
      </li>
      <ng-container *ngIf="(contacts | filter: searchText:'username').length; else noResultsMembers">
        <li class="auto-suggestion" *ngFor="let contact of contacts | filter: searchText:'username' | slice: 0:1000">
          <a class="d-flex align-items-center justify-content-between py-50 w-100" href="/users/{{contact.username}}"
            ><div class="d-flex align-items-center">
              <div class="avatar mr-75"><img [src]="contact.profile_link" alt="png" height="32" /></div>
              <div class="search-data">
                <p class="search-data-title mb-0">{{ contact.username }}</p>
                <small class="text-muted">{{ contact.country_name }}</small>
              </div>
            </div>
            <small class="search-data-size mr-50 text-muted">Joined {{ contact.apiRegistrationDate | date }}</small></a
          >
        </li>
      </ng-container>
      <!-- No results found -->
      <ng-template #noResultsMembers>
        <li class="auto-suggestion justify-content-between">
          <a class="d-flex align-items-center justify-content-between w-100 py-50">
            <div class="d-flex justify-content-start">
              <i data-feather="alert-circle" class="mr-25"></i>
              <span>No results found. Ask your partner to turn the offer</span>
            </div></a
          >
        </li>
      </ng-template>
      <!--/ Members -->
    </ul>
    <!--/ Search List -->
  </div>
</li>
