<div class="content-wrapper container-xxl overflow-hidden p-0">
  <!-- Sidebar filters  -->
  <div class="sidebar-detached sidebar-left">
    <div class="sidebar">
      <core-sidebar
        class="sidebar-shop"
        id="ecommerce-sidebar-toggler"
        name="ecommerce-sidebar"
        collapsibleSidebar="bs-gt-lg"
        overlayClass="body-content-overlay"
      >
        <ecommerce-sidebar
          [type]="type"
          (notify)="onNotify($event)"
          (sliderChange)="onSliderChange($event)"
          (tagChange)="onTagChange($event)"
        ></ecommerce-sidebar>
      </core-sidebar>
    </div>
  </div>
  <!--/ Sidebar filters -->

  <div class="content-detached content-right">
    <div class="content-body">
      <div class="align-items-center">
        <h2>Buy Bitcoin (BTC)</h2>
        <br/>
        <p>
          With bank transfers, online wallets, gift cards, and over 350 other payment
          methods securely with escrow - buyer protection
        </p>
      </div>
      <!-- Multiple Select start -->
      <ngb-alert *ngIf="search_msg" [type]="'primary'" [dismissible]="false" placement="top"
                 ngbTooltip="Protect yourself from phishing and hacking. You must confirm that you are logging in from the our official domain"
      >
        <div class="alert-body font-small-2">
          <p>
            <small class="mr-50"><span class="font-weight-bold">{{search_msg}}</span></small>
          </p>

        </div>

      </ngb-alert>
      <section class="multiple-select">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 col-12 mb-1">
                    <!-- Basic Multi select -->
                    <ng-select
                      (change)="changeMethod(filters.method)"
                      [ngStyle]="{'min-width': '100%'}"
                      [items]="methods"
                      bindLabel="method"
                      bindValue="method"
                      [(ngModel)]="filters.method"
                      [loading]="selectBasicLoading"
                    >


                    </ng-select>
                    <!--/ Basic Multi select -->
                  </div>
                  <div class="col-md-6 col-12 mb-1">
                    <fieldset>
                      <div class="input-group">
                        <input type="number" id="search_amount" class="form-control" placeholder="Enter Amount"/>
                        <div class="input-group-append">
                          <button
                            id="btn-text"
                            (click)="modalSelectOpen(modalSelect)"
                            type="button"
                            class="btn btn-outline-primary"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            rippleEffect
                          >
                            $ £ € <i data-feather="chevron-down"></i>
                          </button>
                          <!-- Modal -->
                          <ng-template #modalSelect let-modal>
                            <div class="modal-header">
                              <h4 class="modal-title" id="myModalLabel1">Choose a currency</h4>
                              <button type="button" class="close" (click)="modal.dismiss('Cross click')"
                                      aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body" tabindex="0" ngbAutofocus>
                              <p>Buy BTC with over 200+ currencies all over the world.</p>
                              <ng-select
                                (change)="changeFn(filters.currency)"
                                [items]="currencies"
                                bindValue="currency"
                                [bindLabel]="currency"
                                [(ngModel)]="filters.currency"
                                [searchFn]="customSearchFn">
                                [loading]="selectBasicLoading"
                                >

                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">

                                  <div class="d-flex justify-content-between align-items-center w-100 label-md">
                                    <div class="d-flex align-items-center">
                                      <i class="flag-icon flag-icon-{{item.iso.toLowerCase()}}"></i>
                                      <span class="ml-1">{{item.currency_name}}</span>
                                    </div>


                                    <button
                                      type="button"
                                      class="btn btn-outline-primary"
                                      rippleEffect
                                    >
                                      {{item.currency}}
                                    </button>

                                  </div>


                                </ng-template>


                              </ng-select>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-primary" (click)="modal.close('Accept click')"
                                      rippleEffect>
                                Accept
                              </button>
                            </div>
                          </ng-template>
                          <!-- / Modal -->

                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div class="col-12">
                    <button
                      type="button"
                      class="btn btn-primary btn-block"
                      (click)="filterOffers()"
                      rippleEffect
                    >
                      <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                      <span [data-feather]="'search'" [class]="'mr-25'"></span>FIND OFFERS
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Multiple Select end -->
      <!-- E-commerce Head Section Starts -->

      <!-- E-commerce Products Starts -->
      <!-- <section id="ecommerce-products" [ngClass]="gridViewRef === true ? 'list-view' : 'grid-view'">
  <app-ecommerce-item
    class="card ecommerce-card"
    [product]="product"
    *ngFor="
      let product of products
        | filter: searchText:'name'
        | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
    "
  ></app-ecommerce-item>
</section> -->
      <div class="d-flex justify-content-center kb-search-content">
        <div class="card" *ngIf="offers.length <=0">
          <a>
            <img src="/assets/images/pages/coming-soon.svg" class="card-img-top" alt="knowledge-base-image"/>
            <div class="card-body text-center">
              <h4>No offer found</h4>
              <p class="text-body mt-1 mb-0">Try changing your search criteria to get more Sellers</p>
            </div>
          </a>
        </div>
      </div>

      <div class="checkout-options">
        <div
          style="margin-bottom: 6px"
          class="card"
          *ngFor="
                        let offer of offers
                            | filter: searchText:'name'
                            | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
                    "
        >
          <div class="card-body" style="margin-bottom: -30px">
            <div
              style="margin-bottom: -10px"
              class="coupons input-group input-group-merge"
            >


                                <span
                                  class="form-control input-group-text text-primary"
                                  id="username"
                                >
                                <a [routerLink]="'/users/' + offer.offer.username"
                                > {{ offer.offer.username }}</a>
                                   </span
                                   >

              <div class="input-group-append">
                                <span class="input-group-text text-primary" id="selling-price"
                                >

                                    {{ offer.offer.margin }}%</span
                                >
              </div>
            </div>
            <hr/>
            <div class="price-details" style="margin-bottom: -1px">
              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title">{{ offer.offer.label }}</div>
                  <div class="detail-title detail-total"><i data-feather="thumbs-up"></i> {{offer.positive_feedback}}
                  </div>
                </li>
                <li class="price-detail">
                  <div class="detail-title">
                    <div
                      class="badge badge-pill badge-light-warning"
                    >
                      {{offer.offer.method}}
                    </div>
                    <div *ngFor="let tag of getArray(offer.offer.tags)" class="badge badge-pill badge-light-secondary">
                      {{ tag }}
                    </div>
                  </div>
                  <div class="detail-amt discount-amt text-success">
                    {{ offer.offer.minimum | number: '1.0-0' }}-{{
                    offer.offer.maximum | number: '1.0-0'
                    }}
                    {{ offer.offer.currency }}
                  </div>
                </li>
              </ul>

              <ul class="list-unstyled">
                <li class="price-detail">
                  <div class="detail-title detail-total"></div>
                  <div class="detail-amt font-weight-bolder">
                    <a [routerLink]="'/offers/bitcoin/details/' + offer.offer.idd">
                      <button
                        type="button"
                        class="btn btn-primary btn-block btn-next place-order"
                        rippleEffect
                      >
                        BUY <i class="font-medium-1 fa" [ngClass]="'fa-brands fa-btc text-white'"
                        ></i>

                      </button>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- Checkout Place Order Right ends -->
      </div>

      <!-- E-commerce Products Ends -->

      <!-- E-commerce Pagination Starts -->
      <section id="ecommerce-pagination">
        <div class="row">
          <div class="col-sm-12">
            <div class="d-flex justify-content-center">
              <ngb-pagination
                [collectionSize]="(products | filter: searchText:'name').length"
                [(page)]="page"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              >
                <!-- Custom pagination icons -->
                <ng-template ngbPaginationPrevious
                ><span
                  [data-feather]="'chevron-left'"
                  [class]="'font-weight-bolder'"
                  [size]="18"
                ></span>
                </ng-template>
                <ng-template ngbPaginationFirst
                ><span
                  [data-feather]="'chevrons-left'"
                  [class]="'font-weight-bolder'"
                  [size]="18"
                ></span>
                </ng-template>
                <ng-template ngbPaginationNext
                ><span
                  [data-feather]="'chevron-right'"
                  [class]="'font-weight-bolder'"
                  [size]="18"
                ></span
                ></ng-template>
                <ng-template ngbPaginationLast
                ><span
                  [data-feather]="'chevrons-right'"
                  [class]="'font-weight-bolder'"
                  [size]="18"
                ></span>
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </section>
      <!-- E-commerce Pagination Ends -->
    </div>
  </div>
</div>
