<div class="content-wrapper container-xxl p-0">
  <div class="content-body">

    <ngb-alert [type]="'primary'" [dismissible]="true" *ngIf="success">
      <div class="alert-body">
        <p>{{ msg }}</p>
      </div>
    </ngb-alert>
    <ngb-alert [type]="'danger'" [dismissible]="true" *ngIf="error">
      <div class="alert-body">
        <p>{{ msg }}</p>
      </div>
    </ngb-alert>

    <ngb-alert [type]="'danger'" [dismissible]="false"  *ngIf="currentUser.status == 2 || currentUser.status == 3 || currentUser.status == 4">
      <div class="card">
        <div class="alert-body">
          <p class="text-body-heading">
           This user is Deactivated
          </p>
          <p class="text-black-50">1. This was due to a TOS Violation</p>
          <p class="text-black-50">2. If you have any complains regarding this user contact us immediately</p>

        </div>
      </div>
    </ngb-alert>

    <div id="user-profile">
      <!-- profile header -->
      <div class="row">
        <div class="col-12">
          <div class="card profile-header mb-2">
            <!-- profile cover photo -->
            <img class="card-img-top" src="assets/images/profile/user-uploads/timeline.jpg" alt="User Profile Image"/>
            <!--/ profile cover photo -->

            <div class="position-relative">
              <!-- profile picture -->
              <div class="profile-img-container d-flex align-items-center text-center ">
                <div class="round avatar box-shadow-3 cursor-pointer">
                  <img width="95" height="95" [src]="image" class="rounded img-fluid" alt="Profile Picture"/>
                </div>
                <!-- profile title -->
                <div class="profile-title ml-3">
                  <h2 class="text-white">{{coreConfig.app.appName}}</h2>
                  <p class="text-white">{{ currentUser.username }}</p>
                </div>
              </div>
            </div>


            <!-- tabs pill -->
            <div class="profile-header-nav">
              <!-- navbar -->
              <nav class="navbar navbar-expand-md navbar-light justify-content-end justify-content-md-between w-100">
                <button
                  class="btn btn-icon navbar-toggler"
                  type="button"
                  data-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  (click)="toggleMenu = !toggleMenu"
                  rippleEffect
                >
                  <i data-feather="align-justify" class="font-medium-5"></i>
                </button>

                <!-- collapse  -->
                <div class="collapse navbar-collapse" id="navbarSupportedContent" [ngbCollapse]="toggleMenu">
                  <div class="profile-tabs d-flex justify-content-between flex-wrap mt-1 mt-md-0">
                    <ul class="nav nav-pills mb-0">
                      <li class="mr-1 nav-item">
                        <a class="nav-link font-weight-bold active" rippleEffect (click)="send_coins(external_username)"
                           ngbTooltip="Send Bitcoin to {{external_username}}" placement="top"
                        >
                          <span class="d-none d-md-block">Send Bitcoin</span>
                          <i data-feather="dollar-sign" class="d-block d-md-none"></i>
                        </a>
                      </li>
                      <li class="mr-1 nav-item">
                        <a class="nav-link font-weight-bold active" rippleEffect href="javascript:void(0)"
                           ngbTooltip="Add {{external_username}} to my trusted list" placement="top"
                        >
                          <span class="d-none d-md-block">Trust</span>
                          <i data-feather="user-plus" class="d-block d-md-none"></i>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="button nav-link font-weight-bold" (click)="block(external_username)"
                           href="javascript:void(0)"
                           ngbTooltip="Block {{external_username}} from trading with me" placement="top"
                        >
                          <span class="d-none d-md-block">Block</span>
                          <i data-feather="user-x" class="d-block d-md-none"></i>
                        </a>
                      </li>

                    </ul>

                  </div>
                </div>
                <!--/ collapse  -->
              </nav>
              <!--/ navbar -->
            </div>



          </div>
        </div>


      </div>
      <!--/ profile header -->

      <!-- profile info section -->
      <section id="profile-info">
        <div class="row">
          <!-- left profile info section -->
          <div class="col-lg-12 col-12 order-2 order-lg-1">
            <!-- about -->
            <div class="card">
              <div class="card-body">
                <h5 class="mb-75">About</h5>
                <p class="card-text">
                  {{ currentUser.about}}
                </p>
                <div class="mt-2">
                  <h5 class="mb-75">Fiat</h5>
                  <p class="card-text">{{result.fiat }}</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-75">BTC</h5>
                  <p class="card-text">{{currentUser.balance}}</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-75">Email:</h5>
                  <p class="card-text">{{currentUser.email}}</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-75">Joined:</h5>
                  <p class="card-text">{{currentUser.apiRegistrationDate | date }}</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-75">Lives:</h5>
                  <p class="card-text">{{ currentUser.country_name}}, {{ currentUser.geolocation}}</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-75">Previous Trades:</h5>
                  <p class="card-text">{{prev_trades}} times</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-50">Has Blocked:</h5>
                  <p class="card-text mb-0">{{has_blocked}} People</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-50">Blocked By:</h5>
                  <p class="card-text mb-0">{{blocked_by}} People</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-50">Verified ID:</h5>
                  <p class="card-text mb-0">{{currentUser.verified_id?'Yes':'no'}}</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-50">Verified Address:</h5>
                  <p class="card-text mb-0">{{currentUser.verified_address?'Yes':'no'}}</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-50">Verified Corporate:</h5>
                  <p class="card-text mb-0">{{currentUser.verified_corporate?'Yes':'no'}}</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-50">2FA Option:</h5>
                  <p class="card-text mb-0">{{currentUser.choice_2fa}}</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-50">Account:</h5>
                  <p class="card-text mb-0">{{currentUser.reason}}</p>
                </div>
                <div class="mt-2">
                  <h5 class="mb-50">Joining IP:</h5>
                  <p class="card-text mb-0">{{currentUser.ip}}</p>
                </div>



              </div>
            </div>
            <!--/ about -->

            <!-- twitter feed card -->
            <div class="card">
              <div class="card-body">
                <h5>Feedbacks</h5>
                <!-- twitter feed -->
                <div class="profile-twitter-feed mt-2" *ngFor="let feed of feeds">
                  <div class="d-flex justify-content-start align-items-center mb-1" ngbTooltip = "{{feed.created_at | date:'fullDate'}}">
                    <div class="avatar mr-1">
                      <a href="/users/{{feed.username}}">
                        <img [src]="feed.photo_url" alt="avatar img" height="40" width="40"/>
                      </a>

                    </div>
                    <div class="profile-user-info" href="/users/{{feed.username}}">
                      <h6 class="mb-0">{{ feed.username }}</h6>
                      <a href="javascript:void(0)">
                        <small class="text-muted">{{feed.method}} - {{feed.currency}}</small>
                      </a>
                    </div>
                    <div class="profile-star ml-auto">
                      <i
                        class="font-medium-3 fa"
                        [ngClass]="{
                          'fa-thumbs-up text-success': feed.flag == 'POSITIVE',
                          'fa-thumbs-down text-danger': feed.flag != 'POSITIVE'
                        }"
                      ></i>
                    </div>
                  </div>
                  <p class="card-text mb-50">{{ feed.comment }}</p>
                  <a href="javascript:void(0)">
                    <small *ngIf = "feed.flag == 'POSITIVE'">{{ feed.flag }}</small>
                    <small class="text-danger" ngbTooltip = 'We use a reputation system where users can leave feedback after trading with one another. It helps to gauge if you are dealing with a good trader or not.' *ngIf = "feed.flag == 'NEGATIVE'">{{ feed.flag }}</small>
                  </a>

                  <hr>
                </div>
              </div>
            </div>
            <!--/ twitter feed card -->
          </div>
          <!--/ left profile info section -->

        </div>

        <!-- reload button -->
        <div class="row">
          <div class="col-12 text-center">
            <button
              type="button"
              class="btn btn-sm btn-primary block-element border-0 mb-1"
              rippleEffect
              (click)="loadMore()"
            >
              <span
                role="status"
                aria-hidden="true"
                [class.spinner-border]="loadMoreRef"
                class="spinner-border-sm"
              ></span>
              Load More
            </button>
          </div>
        </div>
        <!--/ reload button -->
      </section>
      <!--/ profile info section -->
      <div class="row match-height">
        <!-- TRADES CARD -->
        <div class="col-lg-4 col-md-6 col-12">
          <div class="card card-apply-job card-transaction">
            <div class="card-body">
              <!-- ONE ELEMENT -->
              <div *ngFor="let trade of result.trade_data">
                <ngb-alert [type]="trade.trade.status === 'OPENED' ? 'primary' : trade.trade.status === 'PAID' ? 'success' : trade.trade.status === 'DISPUTE_OPEN' ? 'warning' : 'primary'" [dismissible]="false">
                  <div class="alert-body">Trade {{ trade.trade.status.replace('_', ' ') | titlecase }}</div>
                </ngb-alert>


                <div class="d-flex justify-content-between align-items-center mb-1">
                  <div class="media">
                    <div class="avatar mr-1">
                      <img
                        src="{{trade.buyer.profile_link}}"
                        alt="Avatar"
                        width="42"
                        height="42"
                      />
                    </div>
                    <div class="media-body">
                      <h5 class="mb-0">{{ trade.buyer.username | titlecase }}</h5>
                      <small class="text-muted">Joined {{ trade.buyer.apiRegistrationDate | date }} from {{ trade.buyer.country_name | titlecase }}</small>

                    </div>
                  </div>
                  <div class="badge badge-pill badge-light-primary">
                    Buyer
                  </div>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <div class="media">
                    <div class="avatar mr-1">
                      <img
                        src="{{trade.seller.profile_link}}"
                        alt="Avatar"
                        width="42"
                        height="42"
                      />
                    </div>
                    <div class="media-body">
                      <h5 class="mb-0">{{ trade.seller.username | titlecase }}</h5>
                      <small class="text-muted">Joined {{ trade.seller.apiRegistrationDate | date }} from {{ trade.seller.country_name | titlecase }}</small>

                    </div>
                  </div>
                  <div class="badge badge-pill badge-light-warning">
                    Seller
                  </div>
                </div>
                <div class="transaction-item">
                  <div class="media">
                    <div class="avatar bg-light-primary rounded">
                      <div class="avatar-content">
                        <i
                          data-feather="pocket"
                          class="avatar-icon font-medium-3"
                        ></i>
                      </div>
                    </div>
                    <div class="media-body">
                      <h6 class="transaction-title">
                        {{ trade.trade.fiat_amount_original }}
                        {{ trade.trade.currency }}
                      </h6>
                      <small>{{ trade.trade.btc_amount_with_margin }} BTC</small>
                    </div>
                  </div>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    [routerLink]="'/offers/chat/room/' + trade.trade.id"
                    rippleEffect
                  >
                    VIEW
                  </button>
                </div>
                <div class="d-flex justify-content-between align-items-center mb-1" *ngIf = "trade.trade.status == 'DISPUTE_OPEN'">
                  <div class="media">
                    <div class="avatar bg-light-danger mr-2">
                      <div class="avatar-content">
                        <i data-feather="shield" class="avatar-icon font-medium-3"></i>
                      </div>
                    </div>
                    <div class="media-body">
                      <h5 class="mb-0">Dispute started</h5>
                      <small class="text-muted">{{trade.trade.dispute_time | date :'short'}}</small>
                    </div>
                  </div>
                  <div class="badge badge-pill badge-light-danger" role="button" (click)="openLink()">
                    Support <i data-feather="phone-outgoing" class="avatar-icon font-small-1"></i>
                  </div>
                </div>

                <hr/>
              </div>
              <!-- ONE ELEMENT -->
            </div>
          </div>
        </div>
        <!--/ TRADES CARD -->

        <!-- Transaction card -->
        <div class="col-xl-8 col-md-6 col-12">
          <div class="card card-apply-job card-transaction">
            <div class="card-header">
              <h4 class="card-title">User Offers</h4>
              <div class="custom-control custom-control-primary custom-switch">
                <input
                  type="checkbox"
                  checked
                  class="custom-control-input"
                  id="general_offer"

                />
                <label class="custom-control-label" for="general_offer"></label>
              </div>
            </div>
            <div class="card-body">
              <!-- ONE ELEMENT -->
              <div *ngFor="let offer of result.offer_data; let i = index">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <div class="media">
                    <div
                      class="avatar mr-2 bg-light-primary rounded"
                      placement="right"
                      ngbTooltip="Click here to copy this offer link, share it with your clients to open a trade with this offer"
                      rippleEffect
                      (click)="copy(offer.idd)"
                    >
                      <div class="avatar-content">
                        <i
                          data-feather="copy"
                          class="avatar-icon font-medium-3"
                        ></i>
                      </div>
                    </div>
                    <div class="media-body">
                      <h5
                        class="mb-0"
                        placement="right"
                        ngbTooltip="You have chosen to transact this trade with {{
                                                offer.method
                                            }}. This means you must use {{
                                                offer.method
                                            }} to transact this trade and not any other."
                      >
                        {{ offer.method }}
                      </h5>
                      <small
                        class="text-muted"
                        placement="right"
                        ngbTooltip="This is a {{
                                                offer.type | titlecase
                                            }} Offer. You have chosen to transact this trade with {{
                                                offer.currency
                                            }} as the currency"
                      >{{ offer.type | titlecase }} -
                        {{ offer.currency }}</small
                      >
                    </div>
                  </div>
                  <div class="badge badge-pill"
                       [ngClass]="offer.limit_countries == 'none' ? 'badge-light-primary' : offer.limit_countries == 'blocked' ? 'badge-light-danger' : offer.limit_countries == 'allowed' ? 'badge-light-success' : 'badge-light-info'"
                       placement="left" ngbTooltip="{{ getBlocked(offer) }}">
                    <i data-feather="alert-circle" class="font-medium-5"></i>
                  </div>

                </div>
                <div class="transaction-item">
                  <div class="media">
                    <div class="custom-control custom-switch custom-control-inline">
                      <input
                        type="checkbox"
                        [checked]="offer.status"
                        class="custom-control-input"
                        id="{{offer.idd}}"
                        (change)="toggleOffer(offer.idd,i)"
                      />
                      <label
                        class="custom-control-label"
                        for="{{offer.idd}}"
                      ></label>
                    </div>

                  </div>

                  <div ngbDropdown>
                    <button
                      id="button_change"
                      type="button"
                      class="btn btn-outline-primary"
                      ngbDropdownToggle
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      rippleEffect
                    >
                      MORE
                    </button>
                    <div ngbDropdownMenu class="dropdown-menu-right">

                      <a ngbDropdownItem href="/dashboard/editoffer/{{offer.idd}}">DEAUTHORIZE</a>
                      <div role="separator" class="dropdown-divider"></div>
                      <a ngbDropdownItem (click)="delete_offer(offer.idd)">DELETE</a>
                    </div>
                  </div>


                </div>

                <ngb-alert [type]="'primary'" [dismissible]="false">
                  <div class="alert-body" *ngIf="offer.deauth != 0">
                    This offer was deauthorized. Contact support to reactivate this.
                  </div>
                </ngb-alert>
                <hr/>
              </div>
              <!-- ONE ELEMENT -->
            </div>
          </div>
        </div>
        <!--/ Transaction card -->
      </div>




    </div>
  </div>
</div>

